import { createQueryKeys } from '@lukemorales/query-key-factory';
import { getAllMessages } from '../api/claims/claims-bullpen.api';
import { getData } from '../utils/functional/fp';

export const claims = createQueryKeys('claims', {
  listClaimsFiles: (filters: Record<string, unknown>) => ({
    queryKey: [{ filters }],
    queryFn: () => getAllMessages({ params: filters }).then(getData),
  }),
});
